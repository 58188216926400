import React from "react";

const Card = ({ icon, text }) => {
  return (
    <div className="card flex flex-col items-center cursor-pointer mb-12 p-4">
      <div className="mb-8">
        <img src={icon} alt="alt" className="card-icon" />
      </div>
      <div>{text}</div>
    </div>
  );
};

export default Card;
