import React from "react";
import { useTranslation } from "../hooks/useTranslation";
import Header from "./Header";
import MobileHeader from "./MobileHeader";

const Heading = () => {
  const { translate } = useTranslation();

  const onAboutClick = () => {
    var element = document.getElementById("about");
    var headerOffset = 80;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return (
    <div id="home" className="w-full h-screen m-auto relative">
      <div className="heading-bg"></div>
      <MobileHeader />
      <Header />
      <div className="heading-title text-2xl w-9/12 font-bold text-white">
        <div className="text-4xl md:text-7xl welcome-heading">
          {translate("heading.welcome")}
        </div>
        <div className="heading-text mt-8">
          {translate("heading.internationalTrading")}
        </div>
        <div className="heading-button" onClick={onAboutClick}>
          {translate("heading.button")}
        </div>
      </div>
    </div>
  );
};

export default Heading;
