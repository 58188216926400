import About from "./components/About";
import Footer from "./components/Footer";
import Heading from "./components/Heading";
import OurMission from "./components/OurMission";

function App() {
  return (
    <div className="w-full h-full">
      <Heading />
      <About />
      <OurMission />
      <Footer />
    </div>
  );
}

export default App;
