import React from "react";
import JoesLogo from "../assets/joes-logo.svg";
import PhoneSvg from "../assets/phone.svg";
import EmailSvg from "../assets/email.svg";
import { useTranslation } from "../hooks/useTranslation";

const Footer = () => {
  const { translate } = useTranslation();

  return (
    <div id="contact" className="w-full text-white footer h-80 flex p-8 md:p-0">
      <div className="w-9/12 h-full flex flex-col md:flex-row items-center justify-around m-auto">
        <img src={JoesLogo} alt="Logo" className="w-48 h-48 md:w-72 md:h-72" />
        <div className="flex flex-col">
          <div className="text-3xl mb-4">{translate("contact.contact")}</div>
          <div className="flex items-center mb-4">
            <img
              src={PhoneSvg}
              alt="Phone"
              width="20"
              height="20"
              className="mr-4"
            />
            <div>Chinese: +8613823365494</div>
          </div>
          <div className="flex items-center mb-4">
            <img
              src={PhoneSvg}
              alt="Phone"
              width="20"
              height="20"
              className="mr-4"
            />
            <div>Serbian: +381644869508</div>
          </div>
          <div className="flex items-center">
            <img
              src={EmailSvg}
              alt="Phone"
              width="20"
              height="20"
              className="mr-4"
            />
            <div>joesgoods@hotmail.com</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
