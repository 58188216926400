import React from "react";
import BusinessImg from "../assets/business.jpeg";
import { useTranslation } from "../hooks/useTranslation";

const About = () => {
  const { translate } = useTranslation();

  return (
    <div id="about" className="w-9/12 h-full m-auto my-20 about flex flex-col">
      <div className="flex">
        <div className="w-full lg:w-1/2 p-4 h-full">
          <div className="text-3xl text-center mb-6 uppercase">About us</div>
          <div>
            <div className="mb-4">{translate("about.text1")}</div>
            <div className="mb-4">{translate("about.text2")}</div>
            <div className="">{translate("about.text3")}</div>
          </div>
        </div>
        <div className="flex-1 p-4 hidden lg:block">
          <img src={BusinessImg} alt="Logo" className="about-image" />
        </div>
      </div>
    </div>
  );
};

export default About;
