import React, { useCallback } from "react";
import { useTranslation as useTranslationI18N } from "react-i18next";

export const useTranslation = (language) => {
  const { t: translate, i18n } = useTranslationI18N();

  const setEnglish = useCallback(
    (language) => {
      i18n.changeLanguage(language);
    },
    [i18n]
  );

  const setSerbian = useCallback(
    (language) => {
      i18n.changeLanguage(language);
    },
    [i18n]
  );

  const setLanguage = (language) => {
    switch (language) {
      case "en":
        setEnglish(language);
        break;
      case "rs":
        setSerbian(language);
        break;
      default:
        setEnglish(language);
    }
  };

  return {
    translate,
    setLanguage,
  };
};
