export const serbianText = {
  header: {
    home: "Pocetna",
    about: "O Nama",
    ourMission: "Misija",
    contact: "Kontakt",
  },
  heading: {
    welcome: "Dobrodosli u Joe's goods",
    internationalTrading:
      "Medjunarodna trgovacka kompanija sa sedistem u Shenzhen, Kina",
    button: "Vidi vise...",
  },
  about: {
    text1:
      "Joe's Goods - Medjunarodna trgovacka kompanija je usluzno orijentisano izvozno trgovacko preduzece koje pruza domacim medjunarodnim kompanijama mogucnost uvoza i izvoza proizvoda na postojeca trzista.",
    text2:
      "Sa filozofijom ukorenjenom u sirokom poznavanju trzista i licnim odnosima, ciljevi svih ukljucenih poslovnih subjekata se uvek pazljivo procenjuje kako bi se izvrsile besprekorne i profitabilne transakcije.",
    text3:
      "Razvili smo neuporedive mogucnosti usmreavanja za profitabilno medjunarodno poslovanje. Ova osnovana udruzenja predstavljaju neprocenjivo bogatstvo za unapredjenje trgovine medjunarodnog poslovanja.",
  },
  ourMission: {
    title: "Nasa misija",
    card1:
      "Da pomognemo nasim klijentima da prevazidju izazove povezane sa izvozom",
    card2:
      "Da bude fleksibilan i prilagodljiv kako bi radio na nacin koji najbolje odgovara svakom pojedinacnom klijentu",
    card3:
      "Da odrzavamo visoke standarde kvaliteta, vrednosti i profesionalizma tako da klijenti kontinuirano koriste nase usluge",
    card4:
      "Da proaktivno radimo sa nasim klijentima u svakom trenutku kako bismo postigli njihove poslovne ciljeve",
    card5: "Da se ponasamo prema poslu naseg klijenta kao da je nas",
  },
  contact: {
    contact: "Kontakt",
  },
};
