import React, { useState } from "react";
import JoesLogo from "../assets/joes-logo.svg";
import { useTranslation } from "../hooks/useTranslation";

const Header = () => {
  const [visible, setVisible] = useState(false);

  const { translate, setLanguage } = useTranslation();

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const onScrollHandler = (elementId) => {
    var element = document.getElementById(elementId);
    var headerOffset = 80;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <div
      className={`w-full hidden sm:block m-auto header h-16 flex items-center ${
        visible ? "header-visible" : "header-invisible"
      }`}
    >
      <div className="lg:w-3/4 sm:w-11/12 md:w-3/4 xl:w-9/12 h-full m-auto flex">
        <div className="h-full items-center flex sm:w-3/5  md:w-3/5 text-white lg:w-3/5 xl:w-5/12 justify-around uppercase font-bold">
          <div
            className="flex items-center cursor-pointer header-link h-full p-4"
            onClick={() => onScrollHandler("home")}
          >
            {translate("header.home")}
          </div>
          <div
            className="flex items-center cursor-pointer header-link h-full p-4"
            onClick={() => onScrollHandler("about")}
          >
            {translate("header.about")}
          </div>
          <div
            className="flex items-center cursor-pointer header-link h-full p-4"
            onClick={() => onScrollHandler("our-mission")}
          >
            {translate("header.ourMission")}
          </div>
          <div
            className="flex items-center cursor-pointer header-link h-full p-4"
            onClick={() => onScrollHandler("contact")}
          >
            {translate("header.contact")}
          </div>
        </div>
        <div className="ml-auto flex">
          <img
            src={JoesLogo}
            alt="Logo"
            width="100"
            height="100"
            className="ml-auto"
          />
        </div>
        <div className="h-full flex text-white uppercase font-bold absolute top-0 right-10">
          <div
            className="flex items-center cursor-pointer header-link h-full p-4"
            onClick={() => setLanguage("en")}
          >
            EN
          </div>
          <div
            className="flex items-center cursor-pointer header-link h-full p-4"
            onClick={() => setLanguage("rs")}
          >
            RS
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
