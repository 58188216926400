export const englishText = {
  header: {
    home: "Home",
    about: "About",
    ourMission: "Our Mission",
    contact: "Contact",
  },
  heading: {
    welcome: "Welcome to Joe's Goods",
    internationalTrading:
      "International trading company with location in China, Shenzhen.",
    button: "See more...",
  },
  about: {
    text1:
      "Joe’s Goods - International trading company is a service-oriented export trading company providing domestic and international companies the opportunity to import and export products to new and existing markets.",
    text2:
      "With a philosophy deeply rooted in extensive market knowledge and personal relationships, the objectives of all the business entities involved are always carefully evaluated in order to execute flawless and profitable transactions.",
    text3:
      "We have developed unparalleled networking capabilities for profitable international business. These established associations are invaluable assets for the promotion of trade and international business.",
  },
  ourMission: {
    title: "Our Mission",
    card1:
      "To help our clients overcome the challenges associated with exporting",
    card2:
      "To be flexible and adaptable in order to work in a manner that best suits each individual client",
    card3:
      "To maintain high standards of quality, value and professionalism so that clients continuously utilise our services",
    card4:
      "To work proactively with our clients at all times to achieve their business goals",
    card5: "To treat our client’s business as if it were our own",
  },
  contact: {
    contact: "Contact",
  },
};
