import React from "react";
import Card from "./Card";
import Import1 from "../assets/import1.png";
import Import2 from "../assets/import2.png";
import Import3 from "../assets/import3.png";
import Import4 from "../assets/import4.png";
import Import5 from "../assets/import5.png";
import { useTranslation } from "../hooks/useTranslation";

const OurMission = () => {
  const { translate } = useTranslation();

  const cardItems = [
    {
      text: translate("ourMission.card1"),
      icon: Import1,
    },
    {
      text: translate("ourMission.card2"),
      icon: Import2,
    },
    {
      text: translate("ourMission.card3"),
      icon: Import3,
    },
    {
      text: translate("ourMission.card4"),
      icon: Import4,
    },
    {
      text: translate("ourMission.card5"),
      icon: Import5,
    },
  ];
  return (
    <div id="our-mission" className="py-12 w-9/12 m-auto">
      <div className="mb-12 uppercase text-3xl text-center lg:text-left">
        {translate("ourMission.title")}
      </div>
      <div className="flex gap-5 justify-center our-mission flex-wrap ">
        {cardItems.map((card) => {
          return <Card text={card.text} icon={card.icon} key={card.text} />;
        })}
      </div>
    </div>
  );
};

export default OurMission;
